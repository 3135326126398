import type {LinksFunction, LoaderFunctionArgs, MetaFunction} from '@remix-run/node';
import {Links, Meta, Outlet, Scripts, ScrollRestoration, redirect, useLoaderData, useLocation, useRouteError} from '@remix-run/react';
import {captureRemixErrorBoundaryError} from '@sentry/remix';
import {Suspense, useEffect} from 'react';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {GOOGLE_TAG_MANAGER} from '#/app/constants';
import {createMetaTags} from '#/app/helpers/createMetaTags';
import * as gtag from '#/app/helpers/gtag.client';
import '#/app/styles.css';

export const META = {
  description: `Ufleet is AI-powered software that helps retailers optimize delivery routes, reduce costs, and provide stellar experience to customers.`,
  title: 'AI-Powered Route Optimization Software for Efficient Delivery Planning - Ufleet',
};

export const links: LinksFunction = () => [
  {href: 'https://ufleet.io', rel: 'dns-prefetch'},
  {href: 'https://ufleet.io', rel: 'preconnect'},
  {href: '//www.google-analytics.com/', rel: 'preconnect'},
  {href: '//www.googletagmanager.com/', rel: 'preconnect'},
  {href: '//www.gstatic.com/', rel: 'preconnect'},
];

// NOTE: SEO Redirects
export function loader({request}: LoaderFunctionArgs) {
  const {pathname} = new URL(request.url);
  [
    ['/blog/how-to-plan-delivery-routes-in-2024-free-tools-included', '/blog/how-to-plan-delivery-routes-free-tools'],
    ['/features/routing-optimization', '/features/route-optimization'],
  ].forEach(([from, to]) => {
    if (pathname.indexOf(from) === 0) {
      throw redirect(to, {status: 301});
    }
  });

  return {GOOGLE_RECAPTCHA_KEY: process.env.GOOGLE_RECAPTCHA_KEY};
}

export const meta: MetaFunction = ({location}) => {
  return [
    ...createMetaTags({
      description: META.description,
      image: '/og-image.png?v=1',
      title: META.title,
      url: `https://ufleet.io${location.pathname}`,
    }),
  ];
};

export const ErrorBoundary = () => {
  const error = useRouteError() as Record<string, any>;

  const message = error.message ?? 'Something went wrong';
  const IGNORE_ERRORS = [
    'Text content does not match server-rendered HTML',
    'Hydration failed because the initial UI does not match what was rendered on the server.',
    'There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root will switch to client rendering.',
    'Prop `className` did not match. Server:',
  ];
  if (!IGNORE_ERRORS.includes(message)) {
    captureRemixErrorBoundaryError(error);
  }

  console.error(error);
  return <div>Unknown error</div>;
};

let lastVirtualPageviewPageUrl = '';

export default function Root() {
  const location = useLocation();
  const {GOOGLE_RECAPTCHA_KEY} = useLoaderData<typeof loader>();

  useEffect(() => {
    // ld+json
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.innerHTML = JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      name: 'Ufleet',
      url: 'https://ufleet.io',
    });
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // NOTE: Prevent double calling of virtualPageview
      if (location.pathname !== lastVirtualPageviewPageUrl) {
        gtag.pageview(location.pathname, GOOGLE_TAG_MANAGER);
        gtag.event({action: 'virtualPageview', pageTitle: document.title, pageUrl: window.location.href});
        lastVirtualPageviewPageUrl = location.pathname;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={GOOGLE_RECAPTCHA_KEY!}
      scriptProps={{
        appendTo: 'head',
        async: false,
        defer: true,
        nonce: undefined,
      }}
    >
      <html className="h-full" lang="en">
        <head>
          <meta charSet="utf-8" />
          <meta content="text/html; charset=utf-8" httpEquiv="Content-Type" />

          <meta content="IE=edge,chrome=1" httpEquiv="X-UA-Compatible" />
          <meta content="true" name="handheldfriendly" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="telephone=no" name="format-detection" />
          <meta content="no" name="msapplication-tap-highlight" />
          <link href="https://gmpg.org/xfn/11" />

          <link href="/ufleet-mark.png?v=1" rel="shortcut icon" type="image/png" />
          <link href="/apple-touch-icon.png?v=1" />
          <link href="/apple-touch-icon.png?v=1" rel="apple-touch-icon" />
          <link color="#48a3e3" href="/ufleet-mask.svg?v=1" rel="mask-icon" />

          <meta content="Ufleet" property="og:site_name" />
          <meta content="website" property="og:type" />

          <meta content="en_US" property="og:locale" />
          <meta content="https://www.facebook.com/ufleet/" property="article:publisher" />
          <meta content="2020-05-25T14:07:08+00:00" property="article:modified_time" />

          <meta content="summary_large_image" name="twitter:card" />
          <meta content="@ufleetio" name="twitter:site" />

          {process.env.NODE_ENV === 'development' ? null : (
            <Suspense>
              <script
                dangerouslySetInnerHTML={{
                  __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${GOOGLE_TAG_MANAGER}', {
                  page_path: window.location.pathname,
                });
                gtag('consent', 'default', {
                  ad_storage: 'denied',
                  analytics_storage: 'denied',
                  functionality_storage: 'denied',
                  personalization_storage: 'denied',
                  security_storage: 'granted',
                  ad_user_data: 'denied',
                  ad_personalization: 'denied',
                  wait_for_update: 500,
                });
                gtag('set', 'ads_data_redaction', true);
                gtag('set', 'url_passthrough', true);`,
                }}
                data-cookieconsent="ignore"
                id="gtag-init"
                key="gtag-init"
              ></script>
              <script
                dangerouslySetInnerHTML={{
                  __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER}');`,
                }}
              />
              <script
                async
                data-cbid="7be9971c-fff7-4694-95f1-e1ddd0618d90"
                id="Cookiebot"
                src="https://consent.cookiebot.com/uc.js"
                type="text/javascript"
              ></script>
            </Suspense>
          )}

          <Meta />
          <Links />
        </head>
        <body className="h-full" id="root">
          <noscript>
            <iframe
              height="0"
              src={`https://www.googletagmanager.com/ns.html?id=${GOOGLE_TAG_MANAGER}`}
              style={{display: 'none', visibility: 'hidden'}}
              title="GTM"
              width="0"
            ></iframe>
          </noscript>
          <div id="top" />
          <Outlet />
          <ScrollRestoration />
          <Scripts />
          {/* <div className="sticky bottom-0 right-0 flex items-end justify-end">
          <div className="transition-transform translate-y-0 mb-8 mr-8 text-lg inline-block bg-sky-500 rounded-xl px-5 py-3 font-semibold text-white">
            Try free consultation
          </div>
        </div> */}
        </body>
      </html>
    </GoogleReCaptchaProvider>
  );
}
